//////////////////////////////auth actions//////////////////////////////

export const LOGIN = 'LOGIN';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const GET_ROLE = 'GET_ROLE';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';

//////////////////////////////customers actions//////////////////////////////

export const GET_UNREAD_ORDERS = 'GET_UNREAD_ORDERS';
export const GET_UNREAD_ORDERS_SUCCESS = 'GET_UNREAD_ORDERS_SUCCESS';
export const GET_UNREAD_ORDERS_FAIL = 'GET_UNREAD_ORDERS_FAIL';

export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMERS_FAIL = 'GET_CUSTOMERS_FAIL';

export const GET_CERTAIN_CUSTOMER = 'GET_CERTAIN_CUSTOMER';
export const GET_CERTAIN_CUSTOMER_SUCCESS = 'GET_CERTAIN_CUSTOMER_SUCCESS';

export const GET_USER_INNER = 'GET_USER_INNER';
export const GET_USER_INNER_SUCCESS = 'GET_USER_INNER_SUCCESS';

export const GET_REGIONS_BALANCE = 'GET_REGIONS_BALANCE';
export const GET_REGIONS_BALANCE_SUCCESS = 'GET_REGIONS_BALANCE_SUCCESS';
export const GET_REGIONS_BALANCE_FAIL = 'GET_REGIONS_BALANCE_FAIL';

export const GET_BALANCE_INFO = 'GET_BALANCE_INFO';
export const GET_BALANCE_INFO_SUCCESS = 'GET_BALANCE_INFO_SUCCESS';
export const GET_BALANCE_INFO_FAIL = 'GET_BALANCE_INFO_FAIL';

export const GET_CUSTOMER_STATEMENTS = 'GET_CUSTOMER_STATEMENTS';
export const GET_CUSTOMER_STATEMENTS_SUCCESS = 'GET_CUSTOMER_STATEMENTS_SUCCESS';
export const GET_CUSTOMER_STATEMENTS_FAIL = 'GET_CUSTOMER_STATEMENTS_FAIL';

export const GET_FIRSTPAID_ORDERS = 'GET_FIRSTPAID_ORDERS';
export const GET_FIRSTPAID_ORDERS_SUCCESS = 'GET_FIRSTPAID_ORDERS_SUCCESS';
export const GET_FIRSTPAID_ORDERS_FAIL = 'GET_FIRSTPAID_ORDERS_FAIL';

export const BALANCE_FORWARD = 'BALANCE_FORWARD';

export const ADD_CUSTOMER_PAYMENT = 'ADD_CUSTOMER_PAYMENT';

export const GET_CUSTOMER_STATEMENTS_HEADER = 'GET_CUSTOMER_STATEMENTS_HEADER';
export const GET_CUSTOMER_STATEMENTS_HEADER_SUCCESS = 'GET_CUSTOMER_STATEMENTS_HEADER_SUCCESS';
export const GET_CUSTOMER_STATEMENTS_HEADER_FAIL = 'GET_CUSTOMER_STATEMENTS_HEADER_FAIL';

export const GET_USER_SUBS = 'GET_USER_SUBS';
export const GET_USER_SUBS_SUCCESS = 'GET_USER_SUBS_SUCCESS';

export const CUSTOMER_ACTION = 'CUSTOMER_ACTION';
export const CUSTOMER_ACTION_FAIL = 'CUSTOMER_ACTION_FAIL';

export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE';
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS';
export const CUSTOMER_UPDATE_PARTIAL = 'CUSTOMER_UPDATE_PARTIAL';

export const CLEAR_CUSTOMER_SUCCESS = 'CLEAR_CUSTOMER_SUCCESS';
export const HANDLE_CLEAR_SIGNATURE_SUCCESS = 'HANDLE_CLEAR_SIGNATURE_SUCCESS';

export const HANDLE_CLEAR_LOGO_SUCCESS = 'HANDLE_CLEAR_LOGO_SUCCESS';

export const ADD_INFO = 'ADD_INFO';

export const NEED_DOT = 'NEED_DOT';
export const NEED_DOT_SUCCESS = 'NEED_DOT_SUCCESS';

//////////////////////////////categories actions//////////////////////////////

export const PAGINATE = 'PAGINATE';
export const PAGINATE_FAIL = 'PAGINATE_FAIL';

export const GET_CAT = 'GET_CAT';
export const GET_CAT_SUCCESS = 'GET_CAT_SUCCESS';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';

export const GET_CAT_FAIL = 'GET_CAT_FAIL';

export const GET_CURRENT_CAT = 'GET_CURRENT_CAT';
export const GET_CURRENT_CAT_FAIL = 'GET_CURRENT_CAT_FAIL';

export const POST_CAT = 'POST_CAT';
export const POST_CAT_SUCCESS = 'POST_CAT_SUCCESS';
export const POST_CAT_FAIL = 'POST_CAT_FAIL';

export const PUT_CAT = 'PUT_CAT';
export const PUT_CAT_FAIL = 'PUT_CAT_FAIL';


export const PATCH_CAT = 'PATCH_CAT';
export const PATCH_CAT_SUCCESS = 'PATCH_CAT_SUCCESS';
export const PATCH_CAT_FAIL = 'PATCH_CAT_FAIL';


export const DELETE_CAT = 'DELETE_CAT';
export const DELETE_CAT_FAIL = 'DELETE_CAT_FAIL';

export const GET_SUB_CAT = 'GET_SUB_CAT';
export const GET_SUB_CAT_FAIL = 'GET_SUB_CAT_FAIL';

export const POST_SUB_CAT = 'POST_SUB_CAT';
export const POST_SUB_CAT_FAIL = 'POST_SUB_CAT_FAIL';

export const DELETE_SUB_CAT = 'DELETE_SUB_CAT';
export const DELETE_SUB_CAT_FAIL = 'DELETE_SUB_CAT_FAIL';

export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';

export const PUT_PRODUCT = 'PUT_PRODUCT';
export const PUT_PRODUCT_FAIL = 'PUT_PRODUCT_FAIL';

export const POST_PRODUCT = 'POST_PRODUCT';
export const POST_PRODUCT_FAIL = 'POST_PRODUCT_FAIL';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';

//////////////////////////////requests actions//////////////////////////////

export const GET_COMPLETED_REQUESTS = 'GET_COMPLETED_REQUESTS';
export const GET_COMPLETED_REQUESTS_SUCCESS = 'GET_COMPLETED_REQUESTS_SUCCESS';

export const GET_UNCOMPLETED_REQUESTS = 'GET_UNCOMPLETED_REQUESTS';
export const GET_UNCOMPLETED_REQUESTS_SUCCESS = 'GET_UNCOMPLETED_REQUESTS_SUCCESS';

export const PUT_REQUEST_TO_COMPLETED = 'PUT_REQUEST_TO_COMPLETED';
export const PUT_REQUESTS_TO_COMPLETED = 'PUT_REQUESTS_TO_COMPLETED';

export const GET_ALL_ACTIVE_USERS = 'GET_ALL_ACTIVE_USERS';
export const GET_ALL_ACTIVE_USERS_SUCCESS = 'GET_ALL_ACTIVE_USERS_SUCCESS';

//////////////////////////////dashboard actions//////////////////////////////

export const GET_STATISTICS = 'GET_STATISTICS';
export const GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS';

export const GET_DASHBOARD_REQUESTS = 'GET_DASHBOARD_REQUESTS';
export const GET_DASHBOARD_REQUESTS_SUCCESS = 'GET_DASHBOARD_REQUESTS_SUCCESS';

export const GET_REVENUE_FOR_CHART = 'GET_REVENUE_FOR_CHART';
export const GET_REVENUE_FOR_CHART_SUCCESS = 'GET_REVENUE_FOR_CHART_SUCCESS';

//////////////////////////////invoice actions//////////////////////////////

export const GET_INVOICE = 'GET_INVOICE';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';

export const GET_ACTIVITY_ORDER = 'GET_ACTIVITY_ORDER';
export const GET_ACTIVITY_ORDER_SUCCESS = 'GET_ACTIVITY_ORDER_SUCCESS';

export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';

export const GET_INVOICE_OPTIONS = 'GET_INVOICE_OPTIONS';
export const GET_INVOICE_OPTIONS_SUCCESS = 'GET_INVOICE_OPTIONS_SUCCESS';

export const GET_INVOICE_PRODUCT_OPTIONS = 'GET_INVOICE_PRODUCT_OPTIONS';
export const GET_INVOICE_PRODUCT_OPTIONS_SUCCESS = 'GET_INVOICE_PRODUCT_OPTIONS_SUCCESS';

export const POST_INVOICE = 'POST_INVOICE';

//////////////////////////////////proforma//////////////////////////

export const CREATE_PROFORMA = 'CREATE_PROFORMA';
export const CREATE_PROFORMA_SUCCESS = 'CREATE_PROFORMA_SUCCESS';
export const CREATE_PROFORMA_FAIL = 'CREATE_PROFORMA_FAIL';

export const GET_PRFORMA_USERS = 'GET_PRFORMA_USERS';
export const GET_PRFORMA_USERS_SUCCESS = 'GET_PRFORMA_USERS_SUCCESS';

//////////////////////////////////products//////////////////////////

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';

export const PAGINATE_PRODUCTS = 'PAGINATE_PRODUCTS';
export const PAGINATE_PRODUCTS_SUCCESS = 'PAGINATE_PRODUCTS_SUCCESS';

export const GET_SINGLE_PRODUCT = 'GET_SINGLE_PRODUCT';
export const GET_SINGLE_PRODUCT_SUCCESS = 'GET_SINGLE_PRODUCT_SUCCESS';

export const GET_BRANDS = 'GET_BRANDS';
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';

export const ADD_NEW_IMG = 'ADD_NEW_IMG';
export const DELETE_NEW_IMG = 'DELETE_NEW_IMG';

export const ADD_BRAND = 'ADD_BRAND';

/////////////////////////////managers/////////////////////////

export const GET_MANAGERS = 'GET_MANAGERS';
export const GET_MANAGERS_SUCCESS = 'GET_MANAGERS_SUCCESS';

export const GET_CREDIT_MANAGERS = 'GET_CREDIT_MANAGERS';
export const GET_CREDIT_MANAGERS_SUCCESS = 'GET_CREDIT_MANAGERS_SUCCESS';
export const GET_CREDIT_MANAGERS_FAIL = 'GET_CREDIT_MANAGERS_FAIL';

export const CREATE_CREDIT_MANAGER = 'CREATE_CREDIT_MANAGER';
export const CREATE_CREDIT_MANAGER_SUCCESS = 'CREATE_CREDIT_MANAGER_SUCCESS';
export const CREATE_CREDIT_MANAGER_FAIL = 'CREATE_CREDIT_MANAGER_FAIL';

export const CHANGE_CREDIT_MANAGER = 'CHANGE_CREDIT_MANAGER';
export const CHANGE_CREDIT_MANAGER_SUCCESS = 'CHANGE_CREDIT_MANAGER_SUCCESS';
export const CHANGE_CREDIT_MANAGER_FAIL = 'CHANGE_CREDIT_MANAGER_FAIL';

export const DELETE_CREDIT_MANAGER = 'DELETE_CREDIT_MANAGER';
export const DELETE_CREDIT_MANAGER_SUCCESS = 'DELETE_CREDIT_MANAGER_SUCCESS';
export const DELETE_CREDIT_MANAGER_FAIL = 'DELETE_CREDIT_MANAGER_FAIL';

export const CREATE_MANAGER = 'CREATE_MANAGER';
export const CREATE_MANAGER_SUCCESS = 'CREATE_MANAGER_SUCCESS';
export const CREATE_MANAGER_FAIL = 'CREATE_MANAGER_FAIL';

export const DELETE_MANAGER = 'DELETE_MANAGER';
export const CHANGE_MANAGER = 'CHANGE_MANAGER';
export const GET_SALES = 'GET_SALES';
export const GET_SALES_SUCCESS = 'GET_SALES_SUCCESS';

export const CREATE_SALES = 'CREATE_SALES';
export const CREATE_SALES_FAIL = 'CREATE_SALES_FAIL';
export const CREATE_SALES_SUCCESS = 'CREATE_SALES_SUCCESS';

export const DELETE_SALES = 'DELETE_SALES';
export const CHANGE_SALES = 'CHANGE_SALES';
export const GET_SALES_ORDERS = 'GET_SALES_ORDERS';
export const GET_SALES_ORDERS_SUCCESS = 'GET_SALES_ORDERS_SUCCESS';

///////////////////////////orders////////////////////////////////////

export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ACTIVE_ORDERS = 'GET_ACTIVE_ORDERS';
export const GET_ACTIVE_ORDERS_SUCCESS = 'GET_ACTIVE_ORDERS_SUCCESS';

export const GET_DISABLED_ORDERS = 'GET_DISABLED_ORDERS';
export const GET_DISABLED_ORDERS_SUCCESS = 'GET_DISABLED_ORDERS_SUCCESS';
export const GET_DISABLED_ORDERS_FAIL = 'GET_DISABLED_ORDERS_FAIL';

export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';

export const CHANGE_ORDER = 'CHANGE_ORDER';
export const CHANGE_ORDER_SUCCESS = 'CHANGE_ORDER_SUCCESS';
export const CHANGE_ORDER_FAIL = 'CHANGE_ORDER_FAIL';

export const PAYMENT_CREATE = 'PAYMENT_CREATE';
export const PAYMENT_CREATE_SUCCESS = 'PAYMENT_CREATE_SUCCESS';
export const PAYMENT_CREATE_FAIL = 'PAYMENT_CREATE_FAIL';

export const GET_SINGLE_ORDER = 'GET_SINGLE_ORDER';
export const GET_SINGLE_ORDER_SUCCESS = 'GET_SINGLE_ORDER_SUCCESS';

export const PARTIAL_CHANGE_ORDER = 'PARTIAL_CHANGE_ORDER';
export const PARTIAL_CHANGE_ORDER_FAIL = 'PARTIAL_CHANGE_ORDER_FAIL';
export const PARTIAL_CHANGE_ORDER_SUCCESS = 'PARTIAL_CHANGE_ORDER_SUCCESS';

export const REFUND_CREATE = 'REFUND_CREATE';
export const REFUND_CREATE_FAIL = 'REFUND_CREATE_FAIL';
export const REFUND_CREATE_SUCCESS = 'REFUND_CREATE_SUCCESS';

export const MARK_PAID = 'MARK_PAID';
export const MARK_PAID_FAIL = 'MARK_PAID_FAIL';
export const MARK_PAID_SUCCESS = 'MARK_PAID_SUCCESS';

export const USER_QUICK_CREATE = 'USER_QUICK_CREATE';
export const PRODUCT_QUICK_CREATE = 'PRODUCT_QUICK_CREATE';

export const GET_PAYMENTS_LIST = 'GET_PAYMENTS_LIST';
export const GET_PAYMENTS_LIST_SUCCESS = 'GET_PAYMENTS_LIST_SUCCESS';
export const GET_SALES_LIST = 'GET_SALES_LIST';
export const GET_SALES_LIST_SUCCESS = 'GET_SALES_LIST_SUCCESS';

export const CHANGE_TIN = 'CHANGE_TIN';
export const CHANGE_TIN_SUCCESS = 'CHANGE_TIN_SUCCESS';
export const CHANGE_TIN_FAIL = 'CHANGE_TIN_FAIL';

export const SWITCH_TO_INVOICE = 'SWITCH_TO_INVOICE';
export const SWITCH_TO_INVOICE_SUCCESS = 'SWITCH_TO_INVOICE_SUCCESS';
export const SWITCH_TO_INVOICE_FAIL = 'SWITCH_TO_INVOICE_FAIL';

export const APPROVE_PROFORMA = 'APPROVE_PROFORMA';
export const APPROVE_PROFORMA_SUCCESS = 'APPROVE_PROFORMA_SUCCESS';
export const APPROVE_PROFORMA_FAIL = 'APPROVE_PROFORMA_FAIL';

export const POST_ESTIMATE_BALANCE = 'POST_ESTIMATE_BALANCE';
export const POST_ESTIMATE_BALANCE_SUCCESS = 'POST_ESTIMATE_BALANCE_SUCCESS';
export const POST_ESTIMATE_BALANCE_FAIL = 'POST_ESTIMATE_BALANCE_FAIL';

///////////////////////////regions////////////////////////////////////
export const GET_REGIONS = 'GET_REGIONS';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';

export const GET_REGION = 'GET_REGION';
export const GET_REGION_SUCCESS = 'GET_REGION_SUCCESS';

export const CREATE_REGION = 'CREATE_REGION';
export const DELETE_REGION = 'DELETE_REGION';
export const CHANGE_REGION = 'CHANGE_REGION';
export const CHANGE_REGION_SUCCESS = 'CHANGE_REGION_SUCCESS';

export const HANDLE_CLEAR_REGION = 'HANDLE_CLEAR_REGION';
export const HANDLE_CLEAR_REGION_SUCCESS = 'HANDLE_CLEAR_REGION_SUCCESS';

export const HANDLE_CHANGE_REGION = 'HANDLE_CHANGE_REGION';
export const HANDLE_CHANGE_REGION_SUCCESS = 'HANDLE_CHANGE_REGION_SUCCESS';
////////////////////////////subscriptions////////////////////////

export const GET_DEMAND_SUBS = 'GET_DEMAND_SUBS';
export const GET_DEMAND_SUBS_SUCCESS = 'GET_DEMAND_SUBS_SUCCESS';

export const GET_CALENDAR = 'GET_CALENDAR';
export const GET_CALENDAR_SUCCESS = 'GET_CALENDAR_SUCCESS';

export const GET_SINGLE_SUB = 'GET_SINGLE_SUB';
export const GET_SINGLE_SUB_SUCCESS = 'GET_SINGLE_SUB_SUCCESS';

export const POST_SUB = 'POST_SUB';
export const CANCEL_SUB = 'CANCEL_SUB';
export const DELETE_SUB = 'DELETE_SUB';

///////////////////payments//////////////////////////

export const GET_PAYMENTS = 'GET_PAYMENTS';
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';

export const ADD_PAYMENT = 'ADD_PAYMENT';
export const ADD_PAYMENT_FAIL = 'ADD_PAYMENT_FAIL';
export const ADD_PAYMENT_SUCCESS = 'ADD_PAYMENT_SUCCESS';

export const DELETE_PAYMENT = 'DELETE_PAYMENT';

export const INVOICE_LIVE_SEARCH = 'INVOICE_LIVE_SEARCH';
export const INVOICE_LIVE_SEARCH_SUCCESS = 'INVOICE_LIVE_SEARCH_SUCCESS';

export const CHANGE_CUSTOMER = 'CHANGE_CUSTOMER';
export const CHANGE_CUSTOMER_SUCCESS = 'CHANGE_CUSTOMER_SUCCESS';
export const CHANGE_CUSTOMER_FAIL = 'CHANGE_CUSTOMER_FAIL';

export const GET_CUSTOMER_PDF = 'GET_CUSTOMER_PDF';
export const GET_CUSTOMER_PDF_SUCCESS = 'GET_CUSTOMER_PDF_SUCCESS';
export const GET_CUSTOMER_PDF_FAIL = 'GET_CUSTOMER_PDF_FAIL';

export const GET_UNNECESSARY = 'GET_UNNECESSARY';
export const GET_UNNECESSARY_SUCCESS = 'GET_UNNECESSARY_SUCCESS';
export const GET_UNNECESSARY_FAIL = 'GET_UNNECESSARY_FAIL';

export const GET_REPLACED = 'GET_REPLACED';
export const GET_REPLACED_SUCCESS = 'GET_REPLACED_SUCCESS';
export const GET_REPLACED_FAIL = 'GET_REPLACED_FAIL';

export const REPLACE_PRODUCT = 'REPLACE_PRODUCT';
export const REPLACE_PRODUCT_SUCCESS = 'REPLACE_PRODUCT_SUCCESS';
export const REPLACE_PRODUCT_FAIL = 'REPLACE_PRODUCT_FAIL';

export const REPLACE_CUSTOMER = 'REPLACE_CUSTOMER';
export const REPLACE_CUSTOMER_SUCCESS = 'REPLACE_CUSTOMER_SUCCESS';
export const REPLACE_CUSTOMER_FAIL = 'REPLACE_CUSTOMER_FAIL';

export const DISABLE_INVOICE = 'DISABLE_INVOICE';
export const DISABLE_INVOICE_SUCCESS = 'DISABLE_INVOICE_SUCCESS';
export const DISABLE_INVOICE_FAIL = 'DISABLE_INVOICE_FAIL';

export const GET_MANAGER_FULL_INFO = 'GET_MANAGER_FULL_INFO';
export const GET_MANAGER_FULL_INFO_SUCCESS = 'GET_MANAGER_FULL_INFO_SUCCESS';
export const GET_MANAGER_FULL_INFO_FAIL = 'GET_MANAGER_FULL_INFO_FAIL';

export const GET_MANAGER_REPLACED_FULL_INFO = 'GET_MANAGER_REPLACED_FULL_INFO';
export const GET_MANAGER_REPLACED_FULL_INFO_SUCCESS = 'GET_MANAGER_REPLACED_FULL_INFO_SUCCESS';
export const GET_MANAGER_REPLACED_FULL_INFO_FAIL = 'GET_MANAGER_REPLACED_FULL_INFO_FAIL';
